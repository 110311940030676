import React from "react";
import Feed from "./Feed/Feed.js";
import Head from "./Helper/Head.js";

const Home = () => {
  return (
    <section className="container mainContainer">
      <Head title="Fotos" description="Feed de fotos home" />
      <Feed />
    </section>
  );
};

export default Home;
