import React from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import Loading from "./Loading";

const ProtectedRoute = ({ children }) => {
  const { login } = React.useContext(UserContext);
  const [isLogged, setIslogged] = React.useState(null);

  React.useEffect(() => {
    // console.log("chegou aqui", login);
    if (login === true) return setIslogged(true);
    if (login === false) return setIslogged(false);
  }, [login]);

  if (isLogged === false) return <Navigate to="/login" />;
  if (isLogged === true) return children;

  return <Loading />;

  // if (login === null) return <Loading />;
  // if (login) return children;
  // if (!login) return <Navigate to="/login" />;
};

export default ProtectedRoute;
